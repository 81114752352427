@import './colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  // font-family: 'Roboto', sans-serif;
  font-family: 'Nunito', 'sans-serif';
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;

  //Scrollbar
  scrollbar-width: thin;
  scrollbar-color: $main rgb(27, 27, 27);

  &::-webkit-scrollbar {
    width: 6px;
    background: rgb(27, 27, 27);
  }

  &::-webkit-scrollbar-thumb {
    background: $main;
    border-radius: 3px;
  }
}

#App {
  background-color: rgb(27, 27, 27);

  display: flex;
  flex-direction: column;
  align-items: center;

  #timeline {
    border: 1px solid red;
    height: 100%;
    position: absolute;
    top: calc(20px + 100vh);
    left: 20px;
  }

  #siteWrapper {
    width: 100vw;
    height: 100vh;
    // background-color: rgb(58, 58, 58);
    background-color: rgb(27, 27, 27);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }
}
