@import '../../colors.scss';

#headerWrap {
  // border: 1px solid red;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background-color: rgba(0, 0, 0, 0.5);
  #oLogo {
    // border: 1px solid blue;
    margin-left: 20px;
    height: 100%;
  }

  #headerTitle {
    // border: 1px solid red;
    padding-left: 25px;
    display: flex;
    flex-direction: column;

    color: #c9c9c9;
    #headerText {
      font-size: 30px;
    }

    #headerDescription {
      font-size: 18px;
    }

    @media only screen and(max-width: $tablet-m-w ) {
      #headerText {
        font-size: 18px;
      }

      #headerDescription {
        font-size: 10px;
      }
    }
  }

  #menu {
    .menuItem {
      color: #c9c9c9;
      font-size: 18px;
      margin: 25px;
      cursor: pointer;

      &:hover {
        color: #d28307;
      }
    }
  }
}
