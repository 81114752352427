@import '../../colors.scss';

#contactWrapper {
  // border: 1px solid #d28307;
  margin-top: 50px;
  width: 70%;
  min-width: 750px;
  color: white;

  @media only screen and(max-width: $tablet-m-w ) {
    // width: 100%;
    min-width: 0;
    width: 100%;
  }

  .headlineWrapper {
    overflow: hidden;
    padding: 20px;
    .chapter {
      height: 100%;
      font-size: 30px;
      text-decoration: underline #d28307;
    }
  }

  #content {
    margin: 0px 50px 50px 50px;
    // height: 100%;
    display: flex;
    justify-content: center;
    background-color: rgb(37, 37, 37);
    box-shadow: 0px 0px 20px 1px rgb(17, 17, 17);

    border-radius: 5px;

    // justify-content: center;
    #icons {
      width: 80%;
      height: 100px;
      // border: 1px solid red;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      cursor: pointer;

      .link {
        // border: 1px solid red;
        width: 100px;
        height: 80%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin: 20px;
      }
      .contactIcons {
        // color: $main;
        width: 100%;
        // margin: 20px;
        // border: 1px solid blue;
        height: 80%;

        &:hover {
          height: 90%;
        }
      }
    }
  }
}
