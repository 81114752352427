@import '../../colors.scss';

#project {
  // border: 1px solid #d2840741;
  // background-color: #0e0e0e;
  background-color: $content;

  min-width: 400px;
  width: 45%;
  height: 450px;
  margin: 10px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 1px $box-shadow;
  padding: 10px;
  position: relative;
  overflow: hidden;

  .hoverOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgb(90, 90, 90);
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: white;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .projectTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 100%;
    margin: 20px;
    span {
      // border-bottom: 0.5px solid $main;
      font-size: 20px;
    }

    &.mod {
      span {
        font-size: 28px;
      }
    }
  }
  .projectContent {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .projectImgContainer {
      // width: auto;
      display: flex;
      justify-content: center;

      .imgSmall {
        max-width: 80%;
        // width: 100%;
      }

      &.mc {
        max-width: 300px;
        max-height: 70%;
      }

      &.mod {
        display: flex;
        justify-content: center;
        // align-items: center;
      }
    }

    .aboutProject {
      // border: 1px solid blue;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
      // height: 100%;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: center;

      &.mod {
        width: 90%;
        font-size: 16px;

        a {
          color: $main;
        }
      }
    }

    .tags {
      position: absolute;
      bottom: 10px;
      left: 10px;
      display: flex;
      justify-content: center;
      z-index: 1;
    }
  }

  .modMain {
    cursor: default;
    width: 100vw;
    height: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background: rgba(27, 27, 27, 0.8);

    z-index: 5;

    .modChild {
      z-index: 6;
      position: fixed;
      top: 50%;
      left: 50%;
      width: 70%;
      height: 80%;
      transform: translate(-50%, -50%);
      background-color: $content;
      border-radius: 5px;

      //Scrollbar
      scrollbar-width: thin;
      scrollbar-color: $main $content;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: $main;
        border-radius: 3px;
      }

      @media only screen and(max-width: $tablet-m-w ) {
        // width: 100%;
        height: 100%;
        width: 100%;
      }

      .close {
        position: fixed;
        right: 5px;
        top: 5px;
        cursor: pointer;
        background-color: rgb(37, 37, 37);
        outline: none;
        border: none;
        margin: 10px;

        .closeContainer {
          width: 20px;
          height: 20px;

          .leftRight {
            height: 2px;
            width: 20px;
            position: absolute;
            margin-top: 9px;
            background-color: $main;
            border-radius: 2px;
            transform: rotate(45deg);
            transition: all 0.3s ease-in;
          }
          .rightLeft {
            height: 2px;
            width: 20px;
            position: absolute;
            margin-top: 9px;
            background-color: $main;
            border-radius: 2px;
            transform: rotate(-45deg);
            transition: all 0.3s ease-in;
          }
          &:hover {
            .leftRight {
              transform: rotate(-45deg);
              background-color: rgb(212, 35, 35);
            }

            .rightLeft {
              transform: rotate(45deg);
              background-color: rgb(212, 35, 35);
            }
          }
        }
      }
    }
  }
}

.largeImg {
  width: 60%;
}
