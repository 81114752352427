@import '../../colors.scss';

#beamer {
  // border: 1px solid blue;
  width: 100%;
  height: 100%;
  // color: #d28307;
  font-size: 32pt;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  #box {
    border-top: 10px solid #d28307;
    border-left: 10px solid #d28307;
    width: 100px;
    height: 100px;
    position: fixed;
    top: 0;
    left: 0px;
    margin-bottom: 30px;
  }
  #container {
    // border: 1px solid white;
    width: 80%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      margin: 10px;
    }

    @media only screen and(max-width: $tablet-m-w ) {
      // width: 100%;
      width: 100%;
    }
  }

  #arrowDown {
    // border: 1px solid red;
    width: 100%;
    height: 120px;
    position: absolute;
    bottom: 0px;
    // right: calc(100vw - 50% - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #rightCorner {
      border-bottom: 10px solid #d28307;
      border-right: 10px solid #d28307;
      width: 100px;
      height: 100px;
      position: fixed;
      bottom: 0;
      right: 0;
    }

    #arrowTextWrapper {
      // border: 1px solid red;
      width: 120px;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        font-size: 15px;
        #arrow {
          height: 35px;
          width: 35px;
        }
      }
      #arrow {
        border-left: 5px solid #d28307;
        border-top: 5px solid #d28307;
        height: 30px;
        width: 30px;
        transform: rotate(-135deg);
      }
      span {
        font-size: 1em;
      }
    }
  }
}
