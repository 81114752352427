@import '../../colors.scss';

#portfolioWrapper {
  // border: 1px solid #d28307;
  margin-top: 50px;
  width: 70%;
  min-width: 750px;
  // height: 1200px;
  color: white;
  // box-shadow: 10px 10px 8px 10px #888888;
  display: flex;
  flex-direction: column;
  // justify-content: center;

  @media only screen and(max-width: $tablet-m-w ) {
    // width: 100%;
    min-width: 0;
    width: 100%;
  }

  .headlineWrapper {
    // border: 1px solid red;
    // height: 50px;
    // width: 100%;
    // overflow: hidden;
    // margin: 20px;
    // display: flex;
    overflow: hidden;
    padding: 20px;
    .chapter {
      // margin: 20px;
      height: 100%;
      font-size: 30px;
      text-decoration: underline #d28307;
      // border-bottom: 1px solid #d28307;
    }
  }

  #projects {
    // margin: 0px 20px 0px 20px;
    display: flex;
    // border: 1px solid red;
    // height: 100%;
    overflow: hidden;
    flex-wrap: wrap;

    justify-content: center;
  }
}
