@import '../../colors.scss';

#aboutWrapper {
  // border: 1px solid #d28307;

  margin-top: 50px;
  width: 70%;
  min-width: 750px;

  color: white;
  // box-shadow: 10px 10px 8px 10px #888888;
  display: flex;
  flex-direction: column;

  @media only screen and(max-width: $tablet-m-w ) {
    // width: 100%;
    min-width: 0;
    width: 100%;
  }

  .headlineWrapper {
    overflow: hidden;
    padding: 20px;
    .chapter {
      // margin: 20px;
      height: 100%;
      font-size: 30px;
      text-decoration: underline #d28307;
      // border-bottom: 1px solid #d28307;
    }
  }

  #content {
    margin: 0px 40px 0px 40px;
    padding: 20px;
    box-shadow: 0px 0px 20px 1px rgb(17, 17, 17);

    display: flex;
    background-color: rgb(37, 37, 37);
    border-radius: 5px;

    // border: 1px solid red;
    height: 100%;
    flex-wrap: wrap;
    // justify-content: center;
  }
}
