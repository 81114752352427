$main: #d28307;
$main-lighter: #f59802;
$main-darker: #d2840798;

$background: rgb(27, 27, 27);
$content: rgb(37, 37, 37);
$box-shadow: rgb(17, 17, 17);
$black-light: #111111;
// Shades of Gray

$gray0: rgba(253, 253, 253, 1);
$gray1: rgba(248, 248, 248, 1);
$gray2: rgba(241, 241, 241, 1);
$gray3: rgba(233, 233, 233, 1);
$gray4: rgba(220, 220, 220, 1);
$gray5: rgba(210, 210, 210, 1);
$gray6: rgba(200, 200, 200, 1);
$gray7: rgba(190, 190, 190, 1);
$gray8: rgba(180, 180, 180, 1);
$gray9: rgba(170, 170, 170, 1);
$gray10: rgba(160, 160, 160, 1);
$gray11: rgba(150, 150, 150, 1);
$gray12: rgba(140, 140, 140, 1);
$gray13: rgba(130, 130, 130, 1);
$gray14: rgba(120, 120, 120, 1);
$gray15: rgba(110, 110, 110, 1);

//Breakpoints - width of the screen when other styles should be applied

// desktop small
$desktop-s-w: 1080px;
$desktop-s-h: 635px;
//ipad 768 x 2014
$tablet-m-w: 768px;
$tablet-m-h: 1024px;
//ipad pro 1024 x 1366
$tablet-l-w: 1025px;
$tablet-l-h: 1366px;
// iphone 5 320 x 568
$phone-s-w: 320px;
$phone-s-h: 568px;
// iphone 6 375 x 667
$phone-m-w: 375px;
$phone-m-h: 667px;
//iphone 6/7/8 plus
$phone-l-w: 414px;
$phone-l-h: 736px;
